import {useEffect, useState} from "react";
import {Skeleton} from "@mui/material";
import MediaCard from "../utilities/MediaCard";

const Apod = () => {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [apod, setApod] = useState({});

	const getApod = () => {
		setLoading(true)
		fetch("api/nasa/apod")
			.then(response => response.json())
			.then((result) => {
					setLoading(false)
					setApod(result)
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
	}

	// Fire on launch
	useEffect(() => {
		getApod()
	}, [])

	if (error) {
		return <div>Error: {error.message}</div>
	} else if (loading) {
		return <div><Skeleton variant={"rectangular"} width={300} height={349.43}/></div>
	} else {
        if (apod.media_type === "image") {
            return (
                <div>
                    <MediaCard image={apod.url}
                               btnName={"Nasa APOD"}
                               link={"https://apod.nasa.gov/apod/astropix.html"}
                               linkName={apod.title}/>
                </div>
            )
        }
        else {
            return (
                <div>
                    <MediaCard image={apod.thumbnail_url}
                               btnName={"Nasa APOD"}
                               link={"https://apod.nasa.gov/apod/astropix.html"}
                               linkName={apod.title}/>
                </div>
            )
        }
	}
}

export default Apod;