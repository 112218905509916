import React, {Component, Fragment} from 'react';
import {NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import authService from './AuthorizeService';
import {ApplicationPaths} from './ApiAuthorizationConstants';
import {Box, Button} from "@mui/material";

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const {isAuthenticated, userName} = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        }
        else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = {local: true};
            return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, logoutState) {
        return (<Box sx={{display: 'flex'}}>
            <Link to={profilePath} style={{textDecoration: 'none'}}>
                <Button
                    sx={{my: 2, color: 'white', display: 'block'}}>
                    {userName}
                </Button>
            </Link>
            <Link to={logoutPath} style={{textDecoration: 'none'}}
                  state={logoutState}>
                <Button
                    sx={{my: 2, color: 'white', display: 'block'}}>
                    Logout
                </Button>
            </Link>
        </Box>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Box sx={{display: 'flex'}}>
            <Link to={registerPath} style={{textDecoration: 'none'}}>
                <Button
                    sx={{my: 2, color: 'white', display: 'block'}}>
                    Sign Up
                </Button>
            </Link>
            <Link to={loginPath} style={{textDecoration: 'none'}}>
                <Button
                    sx={{my: 2, color: 'white', display: 'block'}}>
                    Login
                </Button>
            </Link>
        </Box>);
    }
}
