import {Container, Paper, Typography} from "@mui/material";

const PaperSlip = (props) => {
	return (
		<Paper sx={{width: props.width, textAlign: "center"}}>
			<Typography variant={"h6"} sx={{
				pt: "15px", pb: "15px", pl: "30px",
				backgroundColor: "background.paperTitle",
				textAlign: "left", 
				textTransform: "uppercase"}}>
				{props.title}
			</Typography>
			
			<Container sx={{   
				padding: "20px",
				margin: "20px 0",
				flexGrow: 1,}}>
				{props.children}
			</Container>
		</Paper>
	)
}

export default PaperSlip