import React from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CustomLink from "../themes/link";
import {Link} from "react-router-dom";
import {LoginMenu} from "./api-authorization/LoginMenu";


const pages = ['Recipes', 'Movies', 'About'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Layout = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Container sx={{p: 0}}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Link to={`/`} style={{textDecoration: 'none'}}>
                            <Button sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div">
                                    EASYEDO
                                </Typography>
                            </Button>
                        </Link>

                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit">
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                                keepMounted
                                transformOrigin={{vertical: 'top', horizontal: 'left',}}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{display: {xs: 'block', md: 'none'},}}>
                                <CustomLink to={`/`}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{'Home'}</Typography>
                                    </MenuItem>
                                </CustomLink>
                                {pages.map((page) => (
                                    <CustomLink key={page} to={`/${page}`}>
                                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    </CustomLink>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}, color: 'primary.main'}}>
                            EASYEDO
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page) => (
                                <Link key={page} to={`/${page}`} style={{textDecoration: 'none'}}>
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        sx={{my: 2, color: 'white', display: 'block'}}>
                                        {page}
                                    </Button>
                                </Link>
                            ))}
                        </Box>

                        <Box sx={{flexGrow: 0}}>
                            <LoginMenu>
                            </LoginMenu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            {props.children}

            <Container sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 5, marginBottom: 2}}>
                <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Link to={`/`} style={{textDecoration: 'none'}}>
                        <Button sx={{mr: 2}}>
                            <Typography
                                variant="caption"
                                noWrap
                                component="div">
                                Home
                            </Typography>
                        </Button>
                    </Link>

                    {pages.map((page) => (
                        <Link to={`/${page}`} style={{textDecoration: 'none'}}>
                            <Button sx={{mr: 2}}>
                                <Typography
                                    variant="caption"
                                    noWrap
                                    component="div">
                                    {page}
                                </Typography>
                            </Button>
                        </Link>
                    ))}
                </Container>
                <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <Button sx={{mr: 2}}
                                onClick={() => window.location = 'mailto:edarling90@gmail.com'}>
                            <Typography
                                variant="caption"
                                noWrap
                                component="div">
                                Contact
                            </Typography>
                        </Button>
                </Container>
            </Container>
        </Container>
    );
}

export default Layout;
