import React, {useState} from "react";
import {
	Alert,
	Box,
	Button,
	Container,
	Snackbar, 
	Stack,
	TextField,
	Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Editor, EditorContent} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import MenuBar from "../utilities/MenuBar";
import BackButton from "../utilities/BackButton";

const RecipeCreate = () => {
	const [recipe, setRecipe] = useState({})
	const ingredientsEditor = new Editor({
		extensions: [
			StarterKit
		],
		content: recipe.ingredients,
		onUpdate: ({editor}) => {
			ingredientsChange(editor.getHTML())
		}
	})
	const instructionsEditor = new Editor({
		extensions: [
			StarterKit
		],
		content: recipe.instructions,
		onUpdate: ({editor}) => {
			instructionsChange(editor.getHTML())
		}
	})
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [snackCreate, setSnackCreate] = useState(false)
	const [snackError, setSnackError] = useState(false)
	const navigate = useNavigate()

	if (error) return <div>Error: {error.message}</div>

	const nameChange = (event) => recipe.name = event.target.value
	const linkChange = (event) => recipe.link = event.target.value
	const timeChange = (event) => recipe.time = event.target.value
	const ingredientsChange = (content) => recipe.ingredients = content
	const instructionsChange = (content) => recipe.instructions = content

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackCreate(false)
		setSnackError(false)
	};

	const handleSubmit = (event) => {
		event.preventDefault()
		//TODO authenticate
		fetch("api/recipes", {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(recipe)
		})
			.then(() => {
					setLoading(false)
					setRecipe(recipe)
					setSnackCreate(true)
					setTimeout(() => navigate("/recipes"), 1200)
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
		/*
			TODO if not authenticated
			 setSnackError(true)
		 */
	}

	const renderRecipe = (recipe) => {

		return (
			<Stack spacing={2} sx={{mt: 3}}>
				<BackButton/>
				<Container>
					<Typography variant={"h3"} sx={{paddingTop: 3}}>
						Create Recipe
					</Typography>
				</Container>

				<Stack spacing={2}>
					<form onSubmit={handleSubmit}>
						<Stack spacing={2} sx={{paddingBottom: 1}}>
							<TextField type={"text"} id={"name"} placeholder={"Name"} fullWidth required
									   color={"primary"} defaultValue={recipe.name} onChange={nameChange}
									   label={"Name"}/>

							<TextField type={"text"} id={"link"} placeholder={"Reference"} fullWidth
									   color={"primary"}
									   defaultValue={recipe.link} onChange={linkChange}
									   label={"Reference"}/>

							<TextField type={"number"} id={"time"} placeholder={"Time (Hours)"}
									   color={"primary"}
									   defaultValue={recipe.time} onChange={timeChange} label={"Time (Hours)"}/>
						</Stack>

						<Stack spacing={1} sx={{paddingBottom: 20}}>
							<Typography color={"primary"} variant={"h6"}>Ingredients:</Typography>
							<MenuBar editor={ingredientsEditor}></MenuBar>
							<Box sx={{border: 1, borderColor: "text.hint", borderRadius: 1}}>
								<EditorContent editor={ingredientsEditor}/>
							</Box>

							<Typography color={"primary"} variant={"h6"}>Instructions:</Typography>
							<MenuBar editor={instructionsEditor}></MenuBar>
							<Box sx={{border: 1, borderColor: "text.hint", borderRadius: 1}}>
								<EditorContent editor={instructionsEditor}/>
							</Box>

							<Button fullWidth type={"submit"} size={"large"} variant={"outlined"} color={"primary"}>
								Create
							</Button>
						</Stack>
					</form>
				</Stack>

				<Snackbar open={snackCreate} autoHideDuration={6000} onClose={handleSnackClose}>
					<Alert sx={{backgroundColor: "#424242", color: "white"}}
						   onClose={handleSnackClose}
						   variant={"outlined"}
						   severity="success">
						Recipe Created
					</Alert>
				</Snackbar>

				<Snackbar open={snackError} autoHideDuration={6000} onClose={handleSnackClose}>
					<Alert sx={{backgroundColor: "#424242", color: "white"}}
						   onClose={handleSnackClose}
						   variant={"outlined"}
						   severity="error">
						You must have a user account to edit and delete recipes. Request one from
						edarling90@gmail.com
					</Alert>
				</Snackbar>
			</Stack>
		)
	}

	return (
		renderRecipe(recipe)
	)
}

export default RecipeCreate;