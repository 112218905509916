import {Typography} from "@mui/material";

const AQITypography = (props) => {

	return (
		<Typography variant={"h2"} sx={{color: props.color}}>
			{props.aqi}
		</Typography>
	)
}

export default AQITypography