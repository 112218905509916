import { styled } from "@mui/material";
import { Link } from "react-router-dom";

const CustomLink = styled(Link)`
	text-decoration: none;
	color: inherit;
	&:hover {
		color: white;
		text-decoration: underline;
	}
`;

export default CustomLink;