import {Button, Card, CardActionArea, CardActions, CardMedia} from "@mui/material";

const MediaCard = (props) => {

    return (
        <Card sx={{width: 300}}>
            <CardActionArea>
                <CardMedia
                    sx={{height: 300, backgroundColor: 'background.paperTitle'}}
                    component={"div"}
                    image={props.image}
                    title={props.btnName}
                    onClick={() => window.open(props.image, "_blank", "noreferrer")}
                />
            </CardActionArea>
            <CardActions sx={{justifyContent: 'center', backgroundColor: 'background.paperTitle'}}>
                {props.link
                    ? <Button href={props.link} color="secondary" target={"_blank"}
                              rel="noreferrer" variant={"outlined"}
                              sx={{
                                  width: '100%',
                                  "&:hover": {color: "secondary.main"}
                              }}>
                        <div style={{
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}>
                            {props.linkName}
                        </div>
                    </Button>
                    :
                    <Button variant={"outlined"} sx={{width: '100%'}} color={"secondary"}
                            onClick={props.func}>
                        <div style={{
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}>
                            {props.btnName}
                        </div>
                    </Button>
                }
            </CardActions>
        </Card>
    )
}

export default MediaCard;