import React from 'react';
import {Button, Container, Grid, Stack, Typography} from "@mui/material";
import PaperSlip from "./utilities/PaperSlip";
import {Fastfood, GitHub, InfoRounded, LinkedIn, MovieRounded} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import RandomCat from "./random/RandomCat";
import Apod from "./nasa/Apod";
import Aqi from "./aqi/Aqi";

const Home = () => {
    const navigate = useNavigate()

    return (
        <Stack spacing={2} sx={{mt: 3}} flexWrap>
            <Container>
                <Typography variant={"h3"}>
                    Welcome To Ethan Darling's Portfolio!
                </Typography>
            </Container>

            <PaperSlip title={"Explore"}>
                <Grid container spacing={3} justifyContent={"center"} sx={{flexGrow: 1}}>
                    <Grid item>
                        <Button size={"large"} variant={"outlined"} color="primary"
                                onClick={() => navigate('/recipes')}
                                startIcon={<Fastfood/>}>
                            Recipes
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button size={"large"} variant={"outlined"} color="primary"
                                onClick={() => navigate('/movies')}
                                startIcon={<MovieRounded/>}>
                            Movies
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button size={"large"} variant={"outlined"} color="primary"
                                onClick={() => navigate('/about')}
                                startIcon={<InfoRounded/>}>
                            About
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button size={"large"} variant={"outlined"} color="primary" startIcon={<LinkedIn/>}
                                sx={{
                                    "&:hover": {color: "primary.main"}
                                }}
                                href={"https://www.linkedin.com/in/ethan-darling-484a53149/"}
                                target={"_blank"}
                                rel={"noreferrer"}>
                            LinkedIn
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button size={"large"} variant={"outlined"} color="primary" startIcon={<GitHub/>}
                                sx={{
                                    "&:hover": {color: "primary.main"}
                                }}
                                href={"https://github.com/EthanDarling8"}
                                target={"_blank"}
                                rel={"noreferrer"}>
                            GitHub
                        </Button>
                    </Grid>
                </Grid>
            </PaperSlip>

            <PaperSlip title={"Other"}>
                <Grid container justifyContent={"center"} spacing={5}>
                    <Grid item><RandomCat/></Grid>
                    <Grid item><Apod/></Grid>
                    <Grid item><Aqi/></Grid>
                </Grid>
            </PaperSlip>

        </Stack>
    );
}

export default Home;