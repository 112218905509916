import React, {useEffect, useState} from "react";
import {Box, Button, Container, IconButton, Paper, Stack, TextField, Tooltip, Typography} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {Link} from "react-router-dom";
import BackButton from "../utilities/BackButton";
import {AddCircleRounded} from "@mui/icons-material";

const Movie = () => {
	const [searchText, setSearchText] = useState('');
	const [movies, setMovies] = useState([]);
	const [data, setData] = useState(movies);
	const columns = [
		{
			field: 'title', headerName: "Title", width: 400, sortable: true,
			renderCell: (cellValues) => {
				return (
					<Link to={`/movies/${cellValues.row.id}`} style={{textDecoration: 'none'}}>
						<Button
							color="primary">
							{`${cellValues.row.title}`}
						</Button>
					</Link>
				)
			}
		}
	];

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);
		const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
		const filteredRows = movies.filter((row) => {
			return Object.keys(row).some((field) => {
				return searchRegex.test(row[field]);
			});
		});
		setData(filteredRows);
	};

	useEffect(() => {
		fetch("api/movies")
			.then(response => response.json())
			.then((result) => {
					setMovies(result);
					setData(result);
				},
				(error) => {
					//TODO handle errors
				}
			)
	}, []);

	return (
		<div>
			<Stack spacing={2} sx={{mt: 3}} flexWrap>
				<Container>
					<Typography variant={"h3"}>
						Movies
					</Typography>
				</Container>

				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
					   sx={{mt: 3}}>
					<BackButton/>
					<Tooltip title={"Create new movie"}>
						<Link to={"/createMovie"}>
							<Button
								style={{textTransform: "capitalize"}}
								color="primary">
								<AddCircleRounded color={"primary"}/>
							</Button>
						</Link>
					</Tooltip>
				</Stack>
				<Paper sx={{height: 700, p: 0, mt: 1}}>
					<DataGrid
						initialState={{
							pagination: {
								pageSize: 50
							}
						}}
						components={{Toolbar: QuickSearchToolbar}}
						rows={data}
						columns={columns}
						componentsProps={{
							toolbar: {
								value: searchText,
								onChange: (event) => requestSearch(event.target.value),
								clearSearch: () => requestSearch(''),
							},
						}}
					/>
				</Paper>
			</Stack>
		</div>
	);
}

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
	return (
		<Box sx={{p: 0.5, pb: 0}}>
			<TextField
				variant="standard"
				value={props.value}
				onChange={props.onChange}
				placeholder="Search…"
				InputProps={{
					startAdornment: <SearchIcon fontSize="small"/>,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{visibility: props.value ? 'visible' : 'hidden'}}
							onClick={props.clearSearch}>
							<ClearIcon fontSize="small"/>
						</IconButton>
					)
				}}
				sx={{
					width: {
						xs: 1,
						sm: 'auto',
					},
					m: (theme) => theme.spacing(1, 0.5, 1.5),
					'& .MuiSvgIcon-root': {
						mr: 0.5,
					},
					'& .MuiInput-underline:before': {
						borderBottom: 1,
						borderColor: 'divider',
					},
				}}
			/>
		</Box>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

export default Movie;