import {IconButton, Tooltip} from "@mui/material";
import {ArrowBackRounded} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const BackButton = () => {
	const navigate = useNavigate()

	return (
		<div>
			<Tooltip title={"Back"}>
				<IconButton aria-label={"Back"} onClick={() => navigate(-1)} color="primary">
					<ArrowBackRounded/>
				</IconButton>
			</Tooltip>
		</div>
	)
}

export default BackButton