import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Alert, Button, Container, Snackbar, Stack, TextField, Typography} from "@mui/material";
import BackButton from "../utilities/BackButton";

const MovieCreate = () => {
	const [movie, setMovie] = useState({})
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [snackCreate, setSnackCreate] = useState(false)
	const [snackError, setSnackError] = useState(false)
	const navigate = useNavigate()

	const handleSubmit = (event) => {
		event.preventDefault()
		//TODO authenticate
		fetch("api/movies", {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(movie)
		})
			.then(() => {
					setLoading(false)
					setMovie(movie)
					setSnackCreate(true)
					setTimeout(() => navigate("/movies"), 1200)
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
		/*
			TODO if not authenticated
			 setSnackError(true)
		 */
	}

	const titleChange = (event) => movie.title = event.target.value
	const linkChange = (event) => movie.link = event.target.value
	const imageChange = (event) => movie.image = event.target.value
	
	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackCreate(false)
		setSnackError(false)
	};

	if (error) return <div className={"text-light"}>Error: {error.message}</div>

	const renderMovie = (movie) => {

		return (
			<Stack spacing={2} sx={{mt: 3}}>
				<BackButton/>
				<Container>
					<Typography variant={"h3"} sx={{paddingTop: 3}}>
						Create Movie
					</Typography>
				</Container>

				<Stack spacing={2}>
					<form onSubmit={handleSubmit}>
						<Stack spacing={2} sx={{paddingBottom: 1}}>

							<TextField type={"text"} id={"title"} placeholder={"Title"} fullWidth required
									   color={"primary"} defaultValue={movie.title} onChange={titleChange}
									   label={"Title"}/>

							<TextField type={"url"} id={"link"} placeholder={"Link"} fullWidth
									   color={"primary"} defaultValue={movie.link} onChange={linkChange}
									   label={"Link"}/>

							<TextField type={"url"} id={"image"} placeholder={"Image"} fullWidth
									   color={"primary"} defaultValue={movie.image} onChange={imageChange}
									   label={"Image"}/>

							<Button fullWidth type={"submit"} size={"large"} variant={"outlined"} color={"primary"}>
								Create
							</Button>

							<Snackbar open={snackCreate} autoHideDuration={6000} onClose={handleSnackClose}>
								<Alert sx={{backgroundColor: "#424242", color: "white"}}
									   onClose={handleSnackClose}
									   variant={"outlined"}
									   severity="success">
									Movie Created
								</Alert>
							</Snackbar>

							<Snackbar open={snackError} autoHideDuration={6000} onClose={handleSnackClose}>
								<Alert sx={{backgroundColor: "#424242", color: "white"}}
									   onClose={handleSnackClose}
									   variant={"outlined"}
									   severity="error">
									You must have a user account to create movies. Request one from edarling90@gmail.com
								</Alert>
							</Snackbar>
						</Stack>
					</form>
				</Stack>
			</Stack>
		)
	}

	return (
		renderMovie(movie)
	)
}

export default MovieCreate;