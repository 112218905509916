import {useEffect, useState} from "react";
import {Button, Card, CardActions, CardContent, Container, Skeleton, Typography} from "@mui/material";
import AQITypography from "./AqiTypography";

const checkColor = (category) => {
	let num = category
	let colorString = ""
	switch (num) {
		case 1:
			colorString = "#00e400"
			break;
		case 2:
			colorString = "#ffff00"
			break;
		case 3:
			colorString = "#ff7e00"
			break;
		case 4:
			colorString = "#ff0000"
			break;
		case 5:
			colorString = "#8f3f97"
			break;
		case 6:
			colorString = "#7e0023"
			break;
		default:
			colorString = "#00e400"
			break;
	}
	return colorString
}

const Aqi = () => {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [aqi, setAqi] = useState({})

	const getAqi = () => {
		fetch("api/aqi")
			.then(response => response.json())
			.then((result) => {
					setLoading(false)
					setAqi(result)
				},
				(error) => {
					setLoading(false)
					setError(error)
				},
			)
	}

	useEffect(() => {
		getAqi()
	}, [])

	const aqiColor = checkColor(aqi.category)

	if (loading) {
		return (
			<div>
				<Skeleton sx={{color: "white"}} variant={"rectangular"}
						  width={300} height={349.43}>
				</Skeleton>
			</div>
		)
	}
	else {
		return (
			<div>
				<Card sx={{width: 300}}>
					<CardContent sx={{height: 300, backgroundColor: 'background.paperTitle'}}>
						<Typography gutterBottom>
							SLC, UT Air Quality Index
						</Typography>
						<Container sx={{marginTop: "65px"}}>
							{error
								? <Typography variant={"h3"}>Airnow API is down :(</Typography>
								: <div>
									<AQITypography aqi={aqi.aqi} color={aqiColor}/>
									<Typography>Current main pollutant:</Typography>
									<Typography variant={"h5"}>{aqi.parameter}</Typography>
								</div>
							}
						</Container>
					</CardContent>
					<CardActions sx={{justifyContent: 'center', backgroundColor: 'background.paperTitle'}}>
						<Button href={"https://docs.airnowapi.org/"} color="secondary" target={"_blank"}
								sx={{
									width: '100%',
									"&:hover": {color: "secondary.main"}
								}}
								rel="noreferrer" variant={"outlined"}>
							Uses API from here
						</Button>
					</CardActions>
				</Card>
			</div>
		)
	}
}

export default Aqi