import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import Layout from './components/Layout';
import './custom.css';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'dark' ? {
                type: 'dark',
                primary: {
                    main: '#f50057',
                    light: 'rgb(247, 51, 120)',
                    dark: 'rgb(171, 0, 60)',
                    contrastText: '#fff'
                },
                secondary: {
                    main: '#ce5385',
                    light: 'rgb(215, 117, 157)',
                    dark: 'rgb(144, 58, 93)',
                    contrastText: '#fff'
                },
                text: {
                    primary: '#fff',
                    secondary: 'rgba(255, 255, 255, 0.7)',
                    disable: 'rgba(255, 255, 255, 0.5)',
                    hint: 'rgba(255, 255, 255, 0.5)'
                },
                background: {
                    default: "#202020",
                    paperTitle: "#3A3A3A",
                    paper: "#222222"
                },
                error: {
                    main: '#f44336',
                    light: '#fe57373',
                    dark: '#d32f2f',
                    contrastText: '#fff'
                },
                warning: {
                    main: '#ff9800',
                    light: '#ffb74d',
                    dark: '#f57c00',
                    contrastText: 'rgba(0, 0, 0, 0.87)'
                },
                info: {
                    main: '#2196f3',
                    light: '#64b5f6',
                    dark: '#1976d2',
                    contrastText: '#fff'
                },
                success: {
                    main: '#4caf50',
                    light: '#81c784',
                    dark: '#388e3c',
                    contrastText: 'rgba(0, 0, 0, 0.87)'
                },
            }
            : {}),
    },
});

const darkTheme = createTheme(getDesignTokens('dark'));

function MyApp() {
    return (
        <Layout>
            <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, requireAuth, ...rest } = route;
                    return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                })}
            </Routes>
        </Layout>
    );
}
export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <MyApp/>
        </ThemeProvider> 
    );
  }
}
