import React, {useEffect, useState} from "react";
import BackButton from "../utilities/BackButton";
import {
	Alert, Box,
	Button,
	Container,
	Dialog, DialogActions, DialogContent, DialogContentText,
	DialogTitle, Divider,
	FormControlLabel,
	Grid,
	IconButton, Snackbar, Stack,
	Switch, TextField,
	Tooltip,
	Typography
} from "@mui/material";
import {DeleteRounded} from "@mui/icons-material";
import parse from "html-react-parser";
import {useNavigate, useParams} from "react-router-dom";
import {Editor, EditorContent} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import MenuBar from "../utilities/MenuBar";

const RecipeDetails = () => {
	const [recipe, setRecipe] = useState({})
	const ingredientsEditor = new Editor({
		extensions: [
			StarterKit
		],
		content: recipe.ingredients,
		onUpdate: ({editor}) => {
			ingredientsChange(editor.getHTML())
		}
	})
	const instructionsEditor = new Editor({
		extensions: [
			StarterKit
		],
		content: recipe.instructions,
		onUpdate: ({editor}) => {
			instructionsChange(editor.getHTML())
		}
	})
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [snackDelete, setSnackDelete] = useState(false)
	const [snackSave, setSnackSave] = useState(false)
	const [snackError, setSnackError] = useState(false)
	const [deleteDialog, setDeleteDialog] = useState(false)
	const navigate = useNavigate()
	const {id} = useParams();

	// Fire on launch
	useEffect(() => {
		fetch("api/recipes/" + id)
			.then(response => response.json())
			.then((result) => {
					setLoading(false)
					setRecipe(result)
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
	}, [])

	if (error) return <div>Error: {error.message}</div>

	const handleEdit = () => setEdit(!edit)

	const nameChange = (event) => recipe.name = event.target.value
	const linkChange = (event) => recipe.link = event.target.value
	const timeChange = (event) => recipe.time = event.target.value
	const ingredientsChange = (content) => recipe.ingredients = content
	const instructionsChange = (content) => recipe.instructions = content

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackDelete(false)
		setSnackSave(false)
		setSnackError(false)
	};

	const handleSubmit = (event) => {
		event.preventDefault()
		//TODO authenticate
		fetch("api/recipes/", {
			method: "PUT",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(recipe)
		})
			.then(() => {
				setTimeout(() => setSnackSave(true), 1200)
				setEdit(!edit)
			})
		/*
			TODO if not authenticated
			 setSnackError(true)
		 */
	}

	const handleDeleteDialog = () => {
		setDeleteDialog(!deleteDialog)
	}

	const handleDelete = () => {
		//TODO authenticate
		fetch("api/recipes/" + id, {
			method: "DELETE"
		}).then(() => {
				if (!error) {
					setSnackDelete(true);
					setDeleteDialog(false)
					setTimeout(() => navigate("/recipes"), 1200);
				}
			},
			(error) => {
				setError(error)
			}
		)

		/*
			TODO if not authenticated
		   setDeleteDialog(false) setSnackError(true)
		*/
	}


	const renderRecipe = (recipe, edit) => {
		if (!edit) {
			return (
				<Stack spacing={2} sx={{paddingBottom: 20}}>
					<Container>
						<Typography variant={"h3"} sx={{paddingTop: 5}}>
							Recipe Details
						</Typography>
					</Container>

					<Stack spacing={2}>
						<Grid container spacing={2}>
							<Grid item xs sx={{paddingLeft: "20px"}}>
								<FormControlLabel control={<Switch checked={edit} onChange={handleEdit}/>}
												  label="Edit"/>
							</Grid>
							<Grid item s={2}>
								<BackButton/>
							</Grid>
							<Grid item xs={10} sx={{flexBasis: "auto"}}>
								<Tooltip title={"Delete"}>
									<IconButton aria-label={"Delete"} color="secondary" onClick={handleDeleteDialog}>
										<DeleteRounded/>
									</IconButton>
								</Tooltip>
							</Grid>

							<Dialog
								open={deleteDialog}
								onClose={handleDeleteDialog}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								sx={{color: "#424242"}}>
								<DialogTitle id="alert-dialog-title">{"Delete Recipe?"}
								</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										Are you sure you want to delete {recipe.name}?
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleDeleteDialog} color="secondary">
										Cancel
									</Button>
									<Button onClick={handleDelete} color="secondary" autoFocus>
										Delete
									</Button>
								</DialogActions>
							</Dialog>

							<Snackbar open={snackDelete} autoHideDuration={6000} onClose={handleSnackClose}>
								<Alert sx={{backgroundColor: "#424242", color: "white"}}
									   onClose={handleSnackClose}
									   variant={"outlined"}
									   severity="success">
									Recipe Deleted
								</Alert>
							</Snackbar>
						</Grid>

						<Typography variant={"h3"}>{recipe.name}</Typography>

						<Divider/>
					</Stack>


					<Stack spacing={2}>
						<Container>
							<Typography variant={"h5"}>
								<div>
									<Button href={recipe.link} size={"large"} variant={"outlined"} color="primary"
											sx={{
												"&:hover": {color: "primary.main"}
											}}
											target={"_blank"}
											rel="noreferrer">
										Reference
									</Button>
								</div>
							</Typography>
						</Container>

						<Container>
							<Typography color={"primary"} variant={"h5"}>Time:
								<Typography sx={{color: "text.primary", fontSize: "large"}}>
									&emsp;{recipe.time} Hours
								</Typography>
							</Typography>
						</Container>

						<Container>
							<Typography color={"primary"} variant={"h5"}>Ingredients:</Typography>
							<Typography variant={"h6"}>{parse(String(recipe.ingredients))}
							</Typography>
						</Container>

						<Container>
							<Typography color={"primary"} variant={"h5"}>Instructions:</Typography>
							<Typography variant={"h6"}>{parse(String(recipe.instructions))}
							</Typography>
						</Container>
					</Stack>

					<Snackbar open={snackSave} autoHideDuration={6000} onClose={handleSnackClose}>
						<Alert sx={{backgroundColor: "#424242", color: "white"}}
							   onClose={handleSnackClose}
							   variant={"outlined"}
							   severity="success">
							Recipe Saved
						</Alert>
					</Snackbar>

					<Snackbar open={snackError} autoHideDuration={6000} onClose={handleSnackClose}>
						<Alert sx={{backgroundColor: "#424242", color: "white"}}
							   onClose={handleSnackClose}
							   variant={"outlined"}
							   severity="error">
							You must have a user account to edit and delete recipes. Request one from
							edarling90@gmail.com
						</Alert>
					</Snackbar>
				</Stack>
			)
		}
		else {
			return (
				<Stack spacing={2}>
					<Container>
						<Typography variant={"h3"} sx={{paddingTop: 5}}>
							Edit Recipe
						</Typography>
					</Container>

					<Stack spacing={2}>
						<form onSubmit={handleSubmit}>
							<Stack spacing={2} sx={{paddingBottom: 1}}>
								<FormControlLabel control={<Switch checked={edit} onChange={handleEdit}/>}
												  label="Edit"/>

								<TextField type={"text"} id={"name"} placeholder={"Name"} fullWidth required
										   color={"primary"} defaultValue={recipe.name} onChange={nameChange}
										   label={"Name"}/>

								<TextField type={"text"} id={"link"} placeholder={"Reference"} fullWidth
										   color={"primary"}
										   defaultValue={recipe.link} onChange={linkChange}
										   label={"Reference"}/>

								<TextField type={"number"} id={"time"} placeholder={"Time (Hours)"}
										   color={"primary"}
										   defaultValue={recipe.time} onChange={timeChange} label={"Time (Hours)"}/>
							</Stack>

							<Stack spacing={1} sx={{paddingBottom: 20}}>
								<Typography color={"primary"} variant={"h6"}>Ingredients:</Typography>
								<MenuBar editor={ingredientsEditor}></MenuBar>
								<Box sx={{border: 1, borderColor: "text.hint", borderRadius: 1}}>
									<EditorContent editor={ingredientsEditor}/>
								</Box>

								<Typography color={"primary"} variant={"h6"}>Instructions:</Typography>
								<MenuBar editor={instructionsEditor}></MenuBar>
								<Box sx={{border: 1, borderColor: "text.hint", borderRadius: 1}}>
									<EditorContent editor={instructionsEditor}/>
								</Box>

								<Button fullWidth type={"submit"} size={"large"} variant={"outlined"} color={"primary"}>
									Save
								</Button>
							</Stack>
						</form>
					</Stack>

					<Snackbar open={snackError} autoHideDuration={6000} onClose={handleSnackClose}>
						<Alert sx={{backgroundColor: "#424242", color: "white"}}
							   onClose={handleSnackClose}
							   variant={"outlined"}
							   severity="error">
							You must have a user account to edit and delete recipes. Request one from
							edarling90@gmail.com
						</Alert>
					</Snackbar>
				</Stack>
			)
		}
	}

	let contents = loading
		? <Container>
			<Container>
				<Typography variant={"h2"}>
					Recipe Details
				</Typography>
			</Container>
			{/*<Skeleton variant={"rect"} className={classes.skeleton} width={"100%"} height={640}/>*/}
		</Container>
		: renderRecipe(recipe, edit)

	return (
		<Container>
			{contents}
		</Container>
	)
}

export default RecipeDetails;