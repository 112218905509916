import {
	Avatar,
	Button,
	Container, 
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Typography
} from "@mui/material";
import PaperSlip from "./utilities/PaperSlip";
import {Fastfood, GitHub, HomeRounded, LinkedIn, MovieRounded} from "@mui/icons-material";
import {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import BackButton from "./utilities/BackButton";

import weber from "../images/weber.svg";
import bruin from "../images/bruin.png";
import ethan from "../images/ethan.jpg";
import react from "../images/react.svg";
import angular from "../images/angular.png";
import dotNet from "../images/dotNet.png";
import tailwind from "../images/tailwind.png";
import database from "../images/database.jpg";
import linux from "../images/linux.png";
import java from "../images/java.png";

const About = () => {
	const navigate = useNavigate()

	return (
		<Stack spacing={2} sx={{mt: 3}} flexWrap>
			<Container>
				<Typography variant={"h3"}>
					Information About Me
				</Typography>
			</Container>

			<BackButton/>

			<PaperSlip title={"About"}>
				<Grid container spacing={3} alignItems={"center"}>
					<Grid item md={2} xs={5}>
						<Avatar style={{backgroundColor: "white", width: "100px", height: "100px"}} alt={"SLCC"}
								src={ethan}/>
					</Grid>
					<Grid item md={10} xs={7}>
						<Typography align={"left"} variant={"h6"}>
							My name is Ethan Darling! In my free time I enjoy the outdoors, reading, programming,
							watching shows or movies, and spending time with family and friends.
						</Typography>
					</Grid>
				</Grid>
			</PaperSlip>

			<PaperSlip title={"Explore"}>
				<Grid container justifyContent={"center"} spacing={2}>
					<Grid item>
						<Button size={"large"} variant={"outlined"} color="primary"
								onClick={() => navigate('/')}
								startIcon={<HomeRounded/>}
								sx={{
									"&:hover": {color: "primary.main"}
								}}>
							Home
						</Button>
					</Grid>

					<Grid item>
						<Button size={"large"} variant={"outlined"} color="primary"
								onClick={() => navigate('/recipes')}
								startIcon={<Fastfood/>}>
							Recipes
						</Button>
					</Grid>

					<Grid item>
						<Button size={"large"} variant={"outlined"} color="primary"
								onClick={() => navigate('/movies')}
								startIcon={<MovieRounded/>}>
							Movies
						</Button>
					</Grid>

					<Grid item>
						<Button size={"large"} variant={"outlined"} color="primary" startIcon={<LinkedIn/>}
								sx={{
									"&:hover": {color: "primary.main"}
								}}
								href={"https://www.linkedin.com/in/ethan-darling-484a53149/"}
								target={"_blank"}
								rel={"noreferrer"}>
							LinkedIn
						</Button>
					</Grid>

					<Grid item>
						<Button size={"large"} variant={"outlined"} color="primary" startIcon={<GitHub/>}
								sx={{
									"&:hover": {color: "primary.main"}
								}}
								href={"https://github.com/EthanDarling8"}
								target={"_blank"}
								rel={"noreferrer"}>
							GitHub
						</Button>
					</Grid>

				</Grid>
			</PaperSlip>

			<PaperSlip title={"Skills"}>
				<List>
					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar alt={"React"} src={react}/>
						</ListItemAvatar>
						<ListItemText
							primary={"React"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										I am currently using React, .NET 7, MySQL, and Docker to rebuild this website.
										I'm doing this to both improve the website and learn new programming skills.
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

					<Divider variant={"inset"} component={"li"}/>

					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar alt={"Angular"} src={angular}/>
						</ListItemAvatar>
						<ListItemText
							primary={"Angular"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										I have 2 years of professional experience using Angular 16, .NET 7,
										Microsoft SQL Server, and Azure to help build and maintain a large scale Web Application.
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

					<Divider variant={"inset"} component={"li"}/>

					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar alt={"DotNet"} src={dotNet}/>
						</ListItemAvatar>
						<ListItemText
							primary={".NET"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										I have experience using the .NET framework for work and for personal projects to
										build APIs and backend end business logic.
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

					<Divider variant={"inset"} component={"li"}/>

					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar alt={"Tailwind"} src={tailwind}/>
						</ListItemAvatar>
						<ListItemText
							primary={"Tailwind"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										Tailwind is a frontend CSS framework that I have integrated into web applications for work. 
										It is very useful for flex layouts and styling front end components.
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

					<Divider variant={"inset"} component={"li"}/>

					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar alt={"Databases"} src={database}/>
						</ListItemAvatar>
						<ListItemText
							primary={"Databases"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										MySQL is the database I use for my personal projects and I have 2 years of 
										professional experience using Microsoft SQL Server. 
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

					<Divider variant={"inset"} component={"li"}/>

					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar alt={"Linux"} src={linux}/>
						</ListItemAvatar>
						<ListItemText
							primary={"Linux"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										I've been using linux for about 6 years. I started using it in school and continue
										to use it at home and at work. When I was an officer of the Programming Club at 
										Salt Lake Community College, I helped teach students and club members how to use
										linux Mint.
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

					<Divider variant={"inset"} component={"li"}/>

					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar alt={"Java"} src={java}/>
						</ListItemAvatar>
						<ListItemText
							primary={"Java"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										Java was the first object oriented programming language I learned.
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

				</List>
			</PaperSlip>

			<PaperSlip title={"Education"}>
				<List>
					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar style={{backgroundColor: "white"}} alt={"Weber"} src={weber}/>
						</ListItemAvatar>
						<ListItemText
							primary={"Weber State University"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										Bachelor and Associate Degree in Computer Science
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>

					<Divider variant={"inset"} component={"li"}/>

					<ListItem alignItems={"flex-start"}>
						<ListItemAvatar>
							<Avatar style={{backgroundColor: "white"}} alt={"SLCC"} src={bruin}/>
						</ListItemAvatar>
						<ListItemText
							primary={"Salt Lake Community College"}
							secondary={
								<Fragment>
									<Typography
										component={"span"}
										variant={"body2"}
										color={"textPrimary"}
									>
										Associate Degree in Computer Science
									</Typography>
								</Fragment>
							}
						/>
					</ListItem>
				</List>
			</PaperSlip>

		</Stack>
	)
}

export default About;