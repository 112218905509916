import {Box, IconButton, Typography} from "@mui/material";
import {
	Code,
	FormatBold,
	FormatItalic, FormatListBulleted, FormatListNumbered,
	FormatStrikethrough,
	FormatTextdirectionLToR, HorizontalRule, Redo, Undo
} from "@mui/icons-material";

const MenuBar = ({editor}) => {
	if (!editor) {
		return null
	}

	return (
		<Box sx={{border: 1, borderColor: "text.hint", borderRadius: 1}}>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleBold().run()}
						className={editor.isActive('bold') ? 'is-active' : ''}>
				<FormatBold color={"secondary"} fontSize={"small"}/>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleItalic().run()}
						className={editor.isActive('italic') ? 'is-active' : ''}>
				<FormatItalic color={"secondary"} fontSize={"small"}/>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleStrike().run()}
						className={editor.isActive('strike') ? 'is-active' : ''}>
				<FormatStrikethrough color={"secondary"} fontSize={"small"}/>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleCode().run()}
						className={editor.isActive('code') ? 'is-active' : ''}>
				<Code color={"secondary"} fontSize={"small"}/>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().setParagraph().run()}
						className={editor.isActive('paragraph') ? 'is-active' : ''}>
				<FormatTextdirectionLToR color={"secondary"} fontSize={"small"}/>
			</IconButton>

			<IconButton disabled>
				<Typography>|</Typography>
			</IconButton>

			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						className={editor.isActive('bulletList') ? 'is-active' : ''}>
				<FormatListBulleted color={"secondary"} fontSize={"small"}/>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleOrderedList().run()}
						className={editor.isActive('orderedList') ? 'is-active' : ''}>
				<FormatListNumbered color={"secondary"} fontSize={"small"}/>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().setHorizontalRule().run()}>
				<HorizontalRule color={"secondary"} fontSize={"small"}/>
			</IconButton>

			<IconButton disabled>
				<Typography>|</Typography>
			</IconButton>

			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleHeading({level: 1}).run()}
						className={editor.isActive('heading', {level: 1}) ? 'is-active' : ''}>
				<Typography color={"secondary"}>H1</Typography>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleHeading({level: 2}).run()}
						className={editor.isActive('heading', {level: 2}) ? 'is-active' : ''}>
				<Typography color={"secondary"}>H2</Typography>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleHeading({level: 3}).run()}
						className={editor.isActive('heading', {level: 3}) ? 'is-active' : ''}>
				<Typography color={"secondary"}>H3</Typography>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleHeading({level: 4}).run()}
						className={editor.isActive('heading', {level: 4}) ? 'is-active' : ''}>
				<Typography color={"secondary"}>H4</Typography>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleHeading({level: 5}).run()}
						className={editor.isActive('heading', {level: 5}) ? 'is-active' : ''}>
				<Typography color={"secondary"}>H5</Typography>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().toggleHeading({level: 6}).run()}
						className={editor.isActive('heading', {level: 6}) ? 'is-active' : ''}>
				<Typography color={"secondary"}>H6</Typography>
			</IconButton>

			<IconButton disabled>
				<Typography>|</Typography>
			</IconButton>

			<IconButton size={"small"}
						onClick={() => editor.chain().focus().undo().run()}>
				<Undo color={"secondary"} fontSize={"small"}/>
			</IconButton>
			<IconButton size={"small"}
						onClick={() => editor.chain().focus().redo().run()}>
				<Redo color={"secondary"} fontSize={"small"}/>
			</IconButton>
		</Box>
	)
}

export default MenuBar; 