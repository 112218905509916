import {useEffect, useState} from "react";
import MediaCard from "../utilities/MediaCard";
import {Skeleton} from "@mui/material";

const RandomCat = () => {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [cat, setCats] = useState({});

	const getCat = () => {
		setLoading(true)
		fetch("api/cat")
			.then(response => response.json())
			.then((result) => {
					setLoading(false)
					setCats(result)
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
	}

	// Fire on launch
	useEffect(() => {
		getCat()
	}, [])

	if (error) {
		return <div>Error: {error.message}</div>
	} else if (loading) {
		return <div><Skeleton variant={"rectangular"} width={300} height={349.43}/></div>
	} else {
		return <div><MediaCard image={cat.url} func={getCat} btnName={"Random Cat"}/></div>
	}
}

export default RandomCat