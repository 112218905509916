import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
	Alert,
	Button,
	Container, Dialog, DialogActions,
	DialogContent, DialogContentText,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton, Snackbar,
	Stack, Switch, TextField,
	Tooltip,
	Typography
} from "@mui/material";
import BackButton from "../utilities/BackButton";
import {DeleteRounded} from "@mui/icons-material";

const MovieDetails = () => {
	const [movie, setMovie] = useState({})
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [snackDelete, setSnackDelete] = useState(false)
	const [snackSave, setSnackSave] = useState(false)
	const [snackError, setSnackError] = useState(false)
	const [deleteDialog, setDeleteDialog] = useState(false)
	const navigate = useNavigate()
	const {id} = useParams();

	// Fire on launch
	useEffect(() => {
		fetch("api/movies/" + id)
			.then(response => response.json())
			.then((result) => {
					setLoading(false)
					setMovie(result)
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
	}, [])

	if (error) return <div className={"text-light"}>Error: {error.message}</div>

	const handleEdit = () => setEdit(!edit)

	const titleChange = (event) => movie.title = event.target.value
	const linkChange = (event) => movie.link = event.target.value
	const imageChange = (event) => movie.image = event.target.value

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackDelete(false)
		setSnackSave(false)
		setSnackError(false)
	};

	const handleSubmit = (event) => {
		event.preventDefault()
		//TODO authenticate
		fetch("api/movies", {
			method: "PUT",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(movie)
		})
			.then(() => {
					setLoading(false)
					setTimeout(() => setSnackSave(true), 1200)
					setEdit(!edit)
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
		/*
			TODO if not authenticated
			 setSnackError(true)
		 */
	}

	const handleDeleteDialog = () => {
		setDeleteDialog(!deleteDialog)
	}

	const handleDelete = () => {
		//TODO authenticate
		fetch("api/movies/" + id, {
			method: "DELETE"
		}).then(() => {
				if (!error) {
					setLoading(false)
					setSnackDelete(true);
					setDeleteDialog(false)
					setTimeout(() => navigate("/movies"), 1200);
				}
			},
			(error) => {
				setLoading(false)
				setError(error)
			}
		)
		/*
		TODO if not authenticated
		 setDeleteDialog(false)
		 setSnackError(true)
 		*/
	}

	const renderMovie = (movie, edit) => {
		if (!edit) {
			return (
				<Stack spacing={2} sx={{paddingBottom: 20}}>
					<Container>
						<Typography variant={"h3"} sx={{paddingTop: 5}}>
							Movie Details
						</Typography>
					</Container>

					<Stack spacing={2}>
						<Grid container spacing={2}>
							<Grid item xs sx={{paddingLeft: "10px"}}>
								<FormControlLabel control={<Switch checked={edit} onChange={handleEdit}/>}
												  label="Edit"/>
							</Grid>
							<Grid item s={2}>
								<BackButton/>
							</Grid>
							<Grid item xs={10} sx={{flexBasis: "auto"}}>
								<Tooltip title={"Delete"}>
									<IconButton aria-label={"Delete"} color="secondary" 
												onClick={handleDeleteDialog}>
										<DeleteRounded/>
									</IconButton>
								</Tooltip>
							</Grid>

							<Dialog
								open={deleteDialog}
								onClose={handleDeleteDialog}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								sx={{color: "#424242"}}>
								<DialogTitle id="alert-dialog-title">{"Delete Movie?"}
								</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										Are you sure you want to delete {movie.title}?
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleDeleteDialog} color="secondary">
										Cancel
									</Button>
									<Button onClick={handleDelete} color="secondary" autoFocus>
										Delete
									</Button>
								</DialogActions>
							</Dialog>

							<Snackbar open={snackDelete} autoHideDuration={6000} onClose={handleSnackClose}>
								<Alert sx={{backgroundColor: "#424242", color: "white"}}
									   onClose={handleSnackClose}
									   variant={"outlined"}
									   severity="success">
									Movie Deleted
								</Alert>
							</Snackbar>
						</Grid>

						<Typography variant={"h3"}>{movie.title}</Typography>
						<Typography variant={"h5"}>{movie.link}</Typography>
						<Typography variant={"h5"}>{movie.image}</Typography>
					</Stack>

					<Snackbar open={snackSave} autoHideDuration={6000} onClose={handleSnackClose}>
						<Alert sx={{backgroundColor: "#424242", color: "white"}}
							   onClose={handleSnackClose}
							   variant={"outlined"}
							   severity="success">
							Movie Saved
						</Alert>
					</Snackbar>

					<Snackbar open={snackError} autoHideDuration={6000} onClose={handleSnackClose}>
						<Alert sx={{backgroundColor: "#424242", color: "white"}}
							   onClose={handleSnackClose}
							   variant={"outlined"}
							   severity="error">
							You must have a user account to edit and delete movies. Request one from
							edarling90@gmail.com
						</Alert>
					</Snackbar>
				</Stack>
			)
		}
		else {
			return (
				<Stack spacing={2}>
					<Container>
						<Typography variant={"h3"} sx={{paddingTop: 5}}>
							Edit Movie
						</Typography>
					</Container>

					<Stack spacing={2}>
						<form onSubmit={handleSubmit}>
							<Stack spacing={2} sx={{paddingBottom: 1}}>
								<FormControlLabel control={<Switch checked={edit} onChange={handleEdit}/>}
												  label="Edit"/>

								<TextField type={"text"} id={"title"} placeholder={"Title"} fullWidth required
										   color={"primary"} defaultValue={movie.title} onChange={titleChange}
										   label={"Title"}/>

								<TextField type={"url"} id={"link"} placeholder={"Link"} fullWidth
										   color={"primary"} defaultValue={movie.link} onChange={linkChange}
										   label={"Link"}/>

								<TextField type={"text"} id={"image"} placeholder={"Image"} fullWidth
										   color={"primary"} defaultValue={movie.image} onChange={imageChange}
										   label={"Image"}/>
							</Stack>

							<Stack spacing={1} sx={{paddingBottom: 20}}>
								<Button fullWidth type={"submit"} size={"large"} variant={"outlined"} color={"primary"}>
									Save
								</Button>
							</Stack>
						</form>
					</Stack>

					<Snackbar open={snackError} autoHideDuration={6000} onClose={handleSnackClose}>
						<Alert sx={{backgroundColor: "#424242", color: "white"}}
							   onClose={handleSnackClose}
							   variant={"outlined"}
							   severity="error">
							You must have a user account to edit and delete movies. Request one from
							edarling90@gmail.com
						</Alert>
					</Snackbar>
				</Stack>
			)
		}
	}

	let contents = loading
		? <Container>
			<Container>
				<Typography variant={"h2"}>
					Movie Details
				</Typography>
			</Container>
			{/*<Skeleton variant={"h1"} className={classes.skeleton} width={"100%"} height={640}/>*/}
		</Container>
		: renderMovie(movie, edit);

	return (
		<Container>
			{contents}
		</Container>
	)
}

export default MovieDetails;