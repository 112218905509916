import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import {Counter} from "./components/Counter";
import {FetchData} from "./components/FetchData";
import Home from "./components/Home";
import Recipes from "./components/recipe/Recipes";
import RecipeDetails from "./components/recipe/RecipeDetails";
import RecipeCreate from "./components/recipe/RecipeCreate";
import Movies from "./components/movie/Movies";
import MovieDetails from "./components/movie/MovieDetails";
import MovieCreate from "./components/movie/MovieCreate";
import About from "./components/About";

const AppRoutes = [
    {index: true, element: <Home/>},
    
    {path: '/recipes', element: <Recipes/>},
    {path: '/recipes/:id', element: <RecipeDetails/>},
    {path: '/createRecipe', element: <RecipeCreate/>},

    {path: '/movies', element: <Movies/>},
    {path: '/movies/:id', element: <MovieDetails/>},
    {path: '/createMovie', element: <MovieCreate/>},

    {path: '/about', element: <About/>},
    
    {path: '/counter', element: <Counter/>},
    {path: '/fetch-data', requireAuth: true, element: <FetchData/>},
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
